<template>
  <div class="operationTable">
    <comps-table :tableData="tableData" :tableHeader="tableHeader" :pageSize='pageSize' :total='total' @current-events="getList" @save-current="savePage"
      v-loading='isLoading'
      element-loading-text="拼命加载中"
      element-loading-customClass='el-icon-loading'
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.7)"
      v-if="initStatus" :expand='false'
      @size-events="changeSize"
      height="84%">
    </comps-table>
    <nodata :message="'暂无相关日志'" h="400" v-else />
    <!-- <pdf-dialog :visible='pdfVis' :cancelDialog='pdfCancel' :title="pdfName" ref="pdf"/>
    <ofd-dialog :visible='ofdVis' :cancelDialog='ofdCancel' :title="ofdName" ref="ofd"/> -->
    <preview-dialog  ref="pdf"/>

  </div>
</template>

<script>
  import previewDialog from '@/components/previewDialog'
  import compsTable from "@/components/table/table";
  // import { getInvoicePicture }  from '@/api/eInv'
  import moment from 'moment'
  import axios from "axios";
  // import pdfDialog from '../dialog/showPdf'
  // import ofdDialog from '../dialog/showOdf'
  export default {
    props: ['einvType','dateVlaue','userId'],
    name: "operationTable",
    components: { previewDialog ,compsTable},
    data() {
      return {
        tableData: [], // 请求到的表格数据
        tableHeader: [
          // 表头信息
          { prop: "type", label: "分类", center: 'center',width:'130',
            formatter: (row) => {
              const typeMap = {
                '1': '进项发票',
                '2': '销项发票',
              }
              return typeMap[row.type];
            },
          },
          { prop: "invoiceType", label: "发票类型", center: 'center',width:'130',
            formatter: (row) => {
              const typeMap = {
                '1': '电子普票',
                '2': '电子专票',
                '3': '纸质普票',
                '4': '纸质专票',
                '5': '全电普票',
                '6': '全电专票'
              }
              return typeMap[row.invoiceType];
            },
          },
          // {
          //   prop: "invoiceType",
          //   label: "发票类型",
          //   center: 'center',
          //   width:'130px'
          // },
          { prop: "invoiceStatus", label: "发票状态", center: 'center',width:'130',
            render: (h, data) => {
              return (data.row.invoiceAmount>0?<span>正数发票</span>:<span style="color:red">负数发票</span>)
            },
          },
          // {
          //   prop: "invoiceStatus",
          //   label: "发票状态",
          //   center: 'center',
          //   width:'130px'
          // },
          {
            prop: "invoiceCode",
            label: "发票代码",
            center: 'center',
          },
          
          {
            prop: "invoiceNo",
            label: "发票号码",
            center: 'center',
          },
          {
            prop: "invoiceTime",
            label: "开票日期",
            center: 'center',
          },
          {
            prop: "invoiceAmount",
            label: "开票金额",
            center: 'center',
            width:'120'
          },
          {
            prop: "deleteUserName",
            label: "操作人",
            center: 'center',
          },
          {
            prop: "deleteTime",
            label: "删除时间",
            center: 'center',
          },
          {
            prop: "saveType",
            label: "版式文件",
            width:'150',
            saveTypeOperation: [
              {  name: "PDF",clickFun: this.getFileUrl },
              {  name: "OFD",clickFun: this.getFileUrl },
              {  name: "JPG",clickFun: this.getFileUrl },
              {  name: "PNG",clickFun: this.getFileUrl },
            ],
          },
          {
            label: "操作",
            center: 'center',
            width:'120',
            render: (h, data) => {
              return (
                  <span>
                    <el-button class='btn' type="text" v-auth="recycleBin:back" onClick={() => { this.doRecover(data.row);}}>
                      恢复
                    </el-button>
                    <el-button class='btn' type="text" v-auth="recycleBin:delete" onClick={() => { this.doDelete(data.row);}}>
                      删除
                    </el-button>
                  </span>
                );
            },
          },
        ],
        multipleSelection: [],

        //分页查询
        page: 1,
        data: [],
        total: 0,
        pageSize: 20,
        current: '',
        isLoading: false,
        initStatus : false,
        pdfVis: false,
        ofdVis: false,
        pdfName:'',
        ofdName:'',
        itemId:'null',
        imgUrl: undefined,
      };
    },
    methods: {
      changeSize(size) {
        this.pageSize = size
        this.getList()
      },
      // 所有实例列表
      savePage(val) {
        this.current = val
      },
      doDownloadLog(item){
        this.rowData = item;

        this.uploadError(this.rowData.id);


      },
      uploadError(val){
        this.isdeling = true
        axios({
          method: 'get',
          url: '/api/euser/log/downloadAttachment',
          responseType: 'blob',
          params: {
            logId : val
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url;
          var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
          link.setAttribute('download', '错误日志' + now + '.xls')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.isdeling = false
        }).catch(err=>{
          this.isdeling = false
          //einvAlert.error("提示",err)
        })
      },
      getList(val) {
        this.isLoading = true
        this.$store.dispatch("FindRecycleList", {
          companyId:  this.getToken('companyId'), //公司Id
          userId: this.userId, //用户ID
          page: val ? val : this.page,
          rows: this.pageSize,
          type:this.einvType,
          deleteTimeStart : this.dateVlaue!=null ? this.dateVlaue[0]: null,
          deleteTimeEnd : this.dateVlaue!=null ? this.dateVlaue[1] : null,
        }).then(res => {
          this.isLoading = false
          if (res.success) {
              this.initStatus = true;
              this.total = res.totalCount;
              this.tableData = res.data;
          } else {
            this.initStatus = false;
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          this.isLoading = false
        })
      },
      // 发票预览
        getFileUrl(item){
          this.$refs.pdf.getFileUrl(item,false)
        },
      sortEvents(type) {
        console.log(type);
      },
      showPdf(){
        this.pdfVis = true;
      },
      pdfCancel(){
        this.pdfVis = false;
      },
      showOfd(){
        this.ofdVis = true;
      },
      showJpg(){
      },
      ofdCancel(){
        this.ofdVis = false;
      },
      doRecover(item){
        this.isLoading = true;
        this.$store.dispatch("DoRecovery", {
          ids: item.id
        }).then(res => {
          if (res.success) {
            this.getList();
            einvAlert.success("恢复成功","数据恢复成功！！")
            this.isLoading = false;
          } else {
            einvAlert.error("恢复失败",res.msg)
            this.isLoading = false;
          }
        }).catch(err => {
          this.isLoading = false
        })
      },
      doDelete(item){
        let _this=this;
        einvAlert.confirm(function(){
          _this.isLoading = true;
          _this.$store.dispatch("DoDelete", {
            ids: item.id
          }).then(res => {
            if (res.success) {
              _this.getList();
              einvAlert.success("提示","数据删除成功！！")
              _this.isLoading = false;
            } else {
              einvAlert.error("提示",res.msg)
              _this.isLoading = false;
            }
          }).catch(err => {
            _this.isLoading = false
          })
        },'您将要永久删除发票</br>"'+item.invoiceCode+'_'+item.invoiceNo+'"','删除','取消')

      },

    },
    mounted() {
      this.getList();
    },
  };
</script>

<style lang='less' scoped>
  .operationTable{
    height: 100%;
    background: #fff;
  }
  .btn{
    margin-right: 10px !important;
  }
</style>
